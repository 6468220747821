import React, { PureComponent } from "react";
import { createStore } from "redux";
import { Provider } from "react-redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { PersistGate } from "redux-persist/integration/react";
import { Router, Route } from "react-router-dom";
import { createBrowserHistory } from "history";

import ScrollToTop from "./components/ScrollToTop";
import Nav from "./components/Nav";
import Home from "./components/Home";
import Search from "./components/Search";
import Word from "./components/Word";
import List from "./components/List";
import Privacy from "./components/Privacy";
import Sources from "./components/Sources";
import Footer from "./components/Footer";

// Redux
import rootReducer from "./reducers";
const persistConfig = {
	key: "root",
	storage,
	blacklist: ["query", "results", "words", "lists"]
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer);
const persistor = persistStore(store);

const history = createBrowserHistory();

class App extends PureComponent {
	render() {
		return (
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<Router history={history}>
						<ScrollToTop>
							<div className="sans-serif min-h-100 flex flex-column justify-between">
								<div>
									<Nav />
									<div>
										<Route
											exact
											path="/"
											component={Home}
										/>
										<Route
											path="/search"
											component={Search}
										/>
										<Route
											exact
											path="/word/:chinese"
											component={Word}
										/>
										<Route
											path="/list/:id/:title?"
											component={List}
										/>
										<Route
											exact
											path="/privacy"
											component={Privacy}
										/>
										<Route
											exact
											path="/sources"
											component={Sources}
										/>
									</div>
								</div>
								<Route
									path={[
										"/word/:chinese",
										"/list/:id/:title?",
										"/"
									]}
									component={Footer}
								/>
							</div>
						</ScrollToTop>
					</Router>
				</PersistGate>
			</Provider>
		);
	}
}

export default App;
