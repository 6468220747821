import { connect } from "react-redux";
import PureComponent from "./component";

import { performSearch } from "../../actions";

const mapStateToProps = (state, ownProps) => ({
	query: state.query,
	results: state.results[state.query]
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	performSearch: query => performSearch(query, dispatch)
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PureComponent);
