import React, { PureComponent } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { chunk } from "lodash";
import SmallWord from "../SmallWord";

export default class Word extends PureComponent {
	componentDidMount() {
		const { fetchWord } = this.props;
		fetchWord();
	}

	componentDidUpdate(prevProps) {
		const { fetchWord, match } = this.props;
		if (prevProps.match.params.chinese !== match.params.chinese) {
			fetchWord();
		}
	}

	render() {
		const { word, match, location } = this.props;
		const { chinese } = match.params;
		const { pathname } = location;
		if (!word) return null;
		const { simplified, pinyin, alternates = [] } = word;
		const definitions = alternates.reduce((acc, cur) => {
			acc.push(...cur.definitions);
			return acc;
		}, []);
		const definitionStr = `${definitions.join("; ")}`;
		const URL = `https://www.noodlechinese.com${pathname}`;
		const title = `${simplified} - ${pinyin}`;
		return (
			<section className="ph3">
				<Helmet>
					<title>{`${simplified} - ${pinyin} - ${definitionStr}`}</title>
					<meta name="description" content={definitionStr} />
					<meta property="og:url" content={URL} />
					<meta property="og:type" content="website" />
					<meta property="og:title" content={title} />
					<meta property="og:description" content={definitionStr} />
					<meta property="og:image" content="/icon.png" />
				</Helmet>
				<div className="mw8 center flex-l justify-between">
					<div className="mt4 w-100">
						<div className="measure-wide">
							<h1 className="mv0 f2">
								{(word.simplified || chinese)
									.split("")
									.map((character, index) =>
										character === "%" ? (
											character
										) : (
											<Link
												key={character + index}
												to={`/word/${character}`}
												className="link black hover-orange"
											>
												{character}
											</Link>
										)
									)}
							</h1>
							{this.renderDefinitions()}
						</div>
						{this.renderCharacters()}
						{this.renderSentences()}
					</div>
					<div className="mw5-l w-100">
						{this.renderStrokes()}
						{this.renderRelated()}
					</div>
				</div>
			</section>
		);
	}

	renderDefinitions() {
		const { word } = this.props;
		if (!word || !word.definitions) return null;
		const { alternates = [] } = word;
		return alternates.map(alternate => (
			<div key={alternate.pinyin} className="mv3">
				<span className="fw7 f4 mv2 db">{alternate.pinyin}</span>
				{alternate.definitions.map((definition, index) => (
					<span
						key={`${definition}${index}`}
						className="mid-gray db f4 mt2"
					>
						{definition}
					</span>
				))}
			</div>
		));
	}

	renderCharacters() {
		const { word } = this.props;
		if (
			!word ||
			!word.characters ||
			!word.characters.length ||
			word.simplified.length < 2
		)
			return null;
		return (
			<div className="measure-wide mv4">
				<h2 className="f4 mv2">Characters</h2>
				<div className="mhn3">
					{word.characters.map((characterWord, index) => (
						<Link
							key={
								characterWord.simplified +
								characterWord.traditional +
								characterWord.pinyin +
								index
							}
							to={`/word/${characterWord.simplified}`}
							className="db link black"
						>
							<div className="ph3 hover-bg-near-white br2">
								<div className="pv3 bb b--light-gray">
									<SmallWord word={characterWord} />
								</div>
							</div>
						</Link>
					))}
				</div>
			</div>
		);
	}

	renderSentences() {
		const { word } = this.props;
		if (!word || !word.sentences || !word.sentences.length) return null;
		return (
			<div className="measure-wide mv4">
				<h2 className="mv2 f4">Sentences</h2>
				{word.sentences.map(sentence => (
					<div
						key={sentence.simplified}
						className="bb b--light-gray pv3"
					>
						<span className="f4 db">
							{sentence.simplified
								.split("")
								.map((character, index) =>
									character === "%" ? (
										character
									) : (
										<Link
											key={character + index}
											to={`/word/${character}`}
											className="link black hover-orange"
										>
											{character}
										</Link>
									)
								)}
						</span>
						<span className="mid-gray db f5 mt1">
							{sentence.english}
						</span>
					</div>
				))}
			</div>
		);
	}

	renderStrokes() {
		const { word } = this.props;
		if (!word || !word.simplifiedCharacter) return null;
		const outlines = JSON.parse(word.simplifiedCharacter.outlines);
		const medians = JSON.parse(word.simplifiedCharacter.medians);

		let durationSum = 0;
		const strokeWidth = 8;
		return (
			<div className="dn db-l h5 w5 mt4 bg-near-white br3 overflow-hidden">
				<svg viewBox="0 0 1024 1024">
					<g
						strokeDasharray={32}
						strokeDashoffset={-4}
						strokeWidth={strokeWidth}
						stroke="#e3e3e3"
					>
						<path d="M 0 0 L 1024 1024" />
						<path d="M 1024 0 L 0 1024" />
					</g>
					<g
						strokeDasharray={32}
						strokeDashoffset={-16}
						strokeWidth={strokeWidth}
						stroke="#e3e3e3"
					>
						<path d="M 0 512 L 1024 512" />
						<path d="M 512 0 L 512 1024" />
					</g>
					<g transform="scale(1, -1) translate(0, -900)">
						{outlines.map((path, index) => (
							<path key={path} d={path} fill="#ccc" />
						))}

						{outlines.map((path, index) => {
							const medianPoints = chunk(medians[index]).map(
								c => c[0]
							);
							const medianPath = `M ${medianPoints
								.map(point => point.join(" "))
								.join(" L ")}`;
							const clipPathId = `stroke-clip-${index}`;
							const length = pathLength(medianPoints);
							const duration = 0.75;
							const dur = `${duration}s`;
							const dur2 = `${duration / 2}s`;
							const begin = durationSum;
							durationSum += duration;
							return [
								<clipPath key={clipPathId} id={clipPathId}>
									<path d={path} />
								</clipPath>,
								<path
									key={medianPath}
									clipPath={`url(#${clipPathId})`}
									d={medianPath}
									fill="none"
									stroke="rgba(0,0,0,1)"
									strokeWidth={16}
									strokeLinecap="round"
									strokeDasharray={`${length * 2}`}
									strokeDashoffset={length * 2}
								>
									<animate
										attributeType="XML"
										attributeName="stroke-dashoffset"
										begin={1 + begin}
										from={length * 2}
										to={length}
										dur={dur}
										fill="freeze"
									/>
									<animate
										attributeType="XML"
										attributeName="stroke-width"
										begin={1 + begin}
										from={16}
										to={256}
										dur={dur2}
										fill="freeze"
									/>
								</path>
							];
						})}
					</g>
				</svg>
			</div>
		);
	}

	renderRelated() {
		const { word } = this.props;
		if (!word || !word.related || !word.related.length) return null;
		return (
			<div className="mw5-l measure-wide mv4">
				<h2 className="f4 mv2">Related Words</h2>
				<div className="mhn2">
					{word.related.map(relatedWord => (
						<Link
							key={relatedWord.simplified}
							to={`/word/${relatedWord.simplified}`}
							className="link black"
						>
							<div className="ph2 hover-bg-near-white br2">
								<div className="pv2-l pv3 bb b--light-gray">
									<SmallWord word={relatedWord} />
								</div>
							</div>
						</Link>
					))}
				</div>
			</div>
		);
	}
}

function pathLength(points) {
	let length = 0;
	let previousPoint;
	points.forEach(point => {
		if (previousPoint) {
			const xDiff = point[0] - previousPoint[0];
			const yDiff = point[1] - previousPoint[1];
			const distance = Math.sqrt(xDiff * xDiff + yDiff * yDiff);
			length += distance;
		}
		previousPoint = point;
	});
	return length;
}
