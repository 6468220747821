import React, { PureComponent } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

import SmallWord from "../SmallWord";

class SearchBar extends PureComponent {
	state = {
		focused: false,
		hover: false
	};

	constructor(props) {
		super(props);
		this.inputRef = React.createRef();
	}

	render() {
		return (
			<div className="w-100">
				<div className="relative flex justify-end">
					{this.renderInput()}
					{this.renderResults()}
				</div>
			</div>
		);
	}

	renderInput() {
		const { query } = this.props;
		const { focused } = this.state;
		const bgColor = focused ? "bg-near-white" : "bg-light-gray";
		const color = focused ? "black" : "white";
		return (
			<form className="z-999 flex measure-narrow w-100">
				<input
					ref={this.inputRef}
					value={query}
					className={`${bgColor} black pa2 z-99 input-reset br2 bn fw5 w-100 outline-none`}
					placeholder="Search for 中文, English or pin1yin1"
					autoComplete="off"
					autoCorrect="off"
					autoCapitalize="off"
					spellCheck="false"
					onChange={event => this.handleChange(event)}
					onFocus={event => this.setState({ focused: true })}
					onBlur={event => this.setState({ focused: false })}
					onKeyPress={event => {
						if (event.which === 13 /* Enter */) {
							this.setState({ focused: false });
							this.inputRef.current.blur();
							event.preventDefault();
							const query = event.target.value;
							this.props.history.push(`/search?q=${query}`);
						}
					}}
				/>
			</form>
		);
	}

	renderResults() {
		const { query, results } = this.props;
		const { focused, hover } = this.state;
		const hidden = !focused && !hover;
		if (!query || !results || !results.length || hidden) return null;
		return (
			<div>
				<div className="absolute top-100 right-0 mt2 bg-white z-999 mt0 shadow-2 measure-narrow w-100 br2 overflow-hidden">
					{results.slice(0, 5).map(word => (
						<Link
							key={
								word.simplified + word.traditional + word.pinyin
							}
							to={
								word.simplified === "%"
									? "#"
									: `/word/${word.simplified}`
							}
							onMouseDown={() => this.setState({ hover: true })}
							onClick={() => this.setState({ hover: false })}
							className="db link black pa3 bb b--light-gray hover-bg-near-white pointer"
						>
							<SmallWord word={word} />
						</Link>
					))}
				</div>
			</div>
		);
	}

	handleChange(event) {
		const { performSearch } = this.props;
		const query = event.target.value;
		performSearch(query);
	}
}

export default withRouter(SearchBar);
