import React, { PureComponent } from "react";

export default class Privacy extends PureComponent {
	render() {
		return (
			<main class="pa3 sans-serif bg-whit">
				<div class="pv5 lh-copy near-black overflow-hidden f4 measure-wide center">
					<h1 class="mv0 f2 fw6 flex justify-between">
						Privacy Policy
					</h1>

					<p>
						<em>
							Your privacy is critically important to us. At
							Noodle Chinese, we have a few fundamental
							principles:
						</em>
					</p>

					<ul>
						<li>
							We are thoughtful about the personal information we
							ask you to provide and the personal information that
							we collect about you through the operation of our
							services.
						</li>
						<li>
							We store personal information for only as long as we
							have a reason to keep it.
						</li>
						<li>
							We help protect you from overreaching government
							demands for your personal information.
						</li>
						<li>
							We aim for full transparency on how we gather, use,
							and share your personal information.
						</li>
					</ul>

					<p>
						Below is Noodle Chinese, Inc’s privacy policy, which
						incorporates and clarifies these principles.
					</p>

					<h3 id="what-this-policy-covers">
						What This Policy Covers
					</h3>

					<p>
						This Privacy Policy applies to information that we
						collect about you when you use:
					</p>

					<ul>
						<li>Our websites (including noodlechinese.com);</li>
						<li>
							Our mobile applications (including the Noodle
							Chinese mobile app for Android and iOS);
						</li>
					</ul>

					<p>
						Throughout this Privacy Policy we’ll refer to our
						website, mobile applications and other products and
						services collectively as “Services.”
					</p>

					<p>
						Below we explain how we collect, use, and share
						information about you, along with the choices that you
						have with respect to that information.
					</p>

					<p>
						Please note that this Privacy Policy does not apply to
						any of our products or services that have a separate
						privacy policy.
					</p>

					<p>
						If you have any questions about this Privacy Policy,
						please{" "}
						<a href="mailto:privacy@noodlechinese.com">
							contact us
						</a>
						.
					</p>

					<h3 id="information-we-collect">Information We Collect</h3>

					<p>
						We only collect information about you if we have a
						reason to do so–for example, to provide our Services, to
						communicate with you, or to make our Services better.
					</p>

					<p>
						We collect information in three ways: if and when you
						provide information to us, automatically through
						operating our services, and from outside sources. Let’s
						go over the information that we collect.
					</p>

					<p>
						<strong>
							<em>Information You Provide to Us</em>
						</strong>
					</p>

					<p>
						It’s probably no surprise that we collect information
						that you provide to us. The amount and type of
						information depends on the context and how we use the
						information. Here are some examples:
					</p>

					<ul>
						<li>
							<p>
								<strong>Basic Account Information:</strong> We
								ask for basic information from you in order to
								set up your account. For example, we require
								individuals who sign up for a Noodle Chinese
								account to provide a username and email
								address–and that’s it. You may provide us with
								more information–like your name–but we don’t
								require that information to create your account.
							</p>
						</li>

						<li>
							<p>
								<strong>Public Profile Information:</strong> If
								you have an account with us, we collect the
								information that you provide for your public
								profile. For example, if you have a Noodle
								Chinese account, your username is part of that
								public profile, along with any other information
								you put into your public profile. Your public
								profile is just that–public–so please keep that
								in mind when deciding what information you would
								like to include.
							</p>
						</li>

						<li>
							<p>
								<strong>
									Transaction and Billing Information:
								</strong>{" "}
								If you buy something from us – a subscription to
								a Noodle Chinese plan, for example - you will
								provide additional personal and payment
								information that is required to process the
								transaction and your payment, such as your name,
								credit card information, and contact
								information.
							</p>
						</li>

						<li>
							<p>
								<strong>
									Communications with Us (Hi There!):
								</strong>{" "}
								You may also provide us information when you
								respond to surveys, communicate with us about a
								support question, or post a question in our
								public forums.
							</p>
						</li>
					</ul>

					<p>
						<strong>
							<em>Information We Collect Automatically</em>
						</strong>
					</p>

					<p>We also collect some information automatically:</p>

					<ul>
						<li>
							<p>
								<strong>Log Information:</strong> Like most
								online service providers, we collect information
								that web browsers, mobile devices, and servers
								typically make available, such as the browser
								type, IP address, unique device identifiers,
								language preference, referring site, the date
								and time of access, operating system, and mobile
								network information. We collect log information
								when you use our Services.
							</p>
						</li>

						<li>
							<p>
								<strong>Usage Information:</strong> We collect
								information about your usage of our Services. We
								also collect information about what happens when
								you use our Services (e.g., page views, support
								document searches, button clicks) along with
								information about your device (e.g., mobile
								screen size, name of cellular network, and
								mobile device manufacturer). We use this
								information to, for example, provide our
								Services to you, as well as get insights on how
								people use our Services, so we can make our
								Services better.
							</p>
						</li>

						<li>
							<p>
								<strong>Location Information:</strong> We may
								determine the approximate location of your
								device from your IP address. We collect and use
								this information to, for example, calculate how
								many people visit our Services from certain
								geographic regions. We may also collect
								information about your precise location via our
								mobile apps if you allow us to do so through
								your mobile device operating system’s
								permissions.
							</p>
						</li>

						<li>
							<p>
								<strong>Stored Information:</strong> We may
								access information stored on your mobile device
								via our mobile app. We access this stored
								information through your device operating
								system’s permissions. For example, if you give
								us permission to access the photographs on your
								mobile device’s camera roll, our Services may
								access the photos stored on your device.
							</p>
						</li>

						<li>
							<p>
								<strong>
									Information from Cookies &amp; Other
									Technologies:
								</strong>{" "}
								A cookie is a string of information that a
								website stores on a visitor’s computer, and that
								the visitor’s browser provides to the website
								each time the visitor returns. Pixel tags (also
								called web beacons) are small blocks of code
								placed on websites and e-mails. Noodle Chinese
								uses cookies and other technologies like pixel
								tags to help us identify and track visitors,
								usage, and access preferences for our Services,
								as well as track and understand e-mail campaign
								effectiveness and to deliver targeted ads.
							</p>
						</li>
					</ul>

					<p>
						<strong>
							<em>Information We Collect from Other Sources</em>
						</strong>
					</p>

					<p>
						We may also get information about you from other
						sources. For example, if you create or log into your
						Noodle Chinese account through another service (like
						Google) or if you connect your website or account to a
						social media service, we will receive information from
						that service (such as your username, basic profile
						information, and friends list) via the authorization
						procedures used by that service. The information we
						receive depends on which services you authorize and any
						options that are available.
					</p>

					<p>
						We may also get information from third party services
						about individuals who are not yet our users (…but we
						hope will be!), which we may use, for example, for
						marketing and advertising purposes.
					</p>

					<h3 id="how-we-use-information">How We Use Information</h3>

					<p>
						We use information about you as mentioned above and as
						follows:
					</p>

					<ul>
						<li>
							<p>
								To provide our Services–for example, to set up
								and maintain your account, or charge you for any
								of our paid Services; To further develop our
								Services–for example by adding new features that
								we think our users will enjoy or will help them
								to create and manage their websites more
								efficiently;
							</p>
						</li>

						<li>
							<p>
								To monitor and analyze trends and better
								understand how users interact with our Services,
								which helps us improve our Services and make
								them easier to use;
							</p>
						</li>

						<li>
							<p>
								To monitor and protect the security of our
								Services, detect and prevent fraudulent
								transactions and other illegal activities, fight
								spam, and protect the rights and property of
								Noodle Chinese and others;
							</p>
						</li>

						<li>
							<p>
								To communicate with you about offers and
								promotions offered by Noodle Chinese and others
								we think will be of interest to you, solicit
								your feedback, or keep you up to date on Noodle
								and our products; and
							</p>
						</li>

						<li>
							<p>
								To personalize your experience using our
								Services, provide content recommendations and
								serve relevant advertisements.
							</p>
						</li>
					</ul>

					<h3 id="sharing-information">Sharing Information</h3>

					<p>
						<strong>
							<em>How We Share Information</em>
						</strong>
					</p>

					<p>
						We do not sell our users’ private personal information.
					</p>

					<p>
						We share information about you in the limited
						circumstances spelled out below and with appropriate
						safeguards on your privacy:
					</p>

					<ul>
						<li>
							<p>
								<strong>
									Subsidiaries, Employees, and Independent
									Contractors:
								</strong>{" "}
								We may disclose information about you to our
								subsidiaries, our employees, and individuals who
								are our independent contractors that need to
								know the information in order to help us provide
								our Services or to process the information on
								our behalf. We require our subsidiaries,
								employees, and independent contractors to follow
								this Privacy Policy for personal information
								that we share with them.
							</p>
						</li>

						<li>
							<p>
								<strong>Third Party Vendors:</strong> We may
								share information about you with third party
								vendors who need to know information about you
								in order to provide their services to us. This
								group includes vendors that help us provide our
								Services to you (like payment providers that
								process your credit and debit card information)
								and those that help us understand and enhance
								our Services (like analytics providers). We
								require vendors to agree to privacy commitments
								in order to share information with them.
							</p>
						</li>

						<li>
							<p>
								<strong>As Required by Law:</strong> We may
								disclose information about you in response to a
								subpoena, court order, or other governmental
								request.
							</p>
						</li>

						<li>
							<p>
								<strong>To Protect Rights and Property:</strong>{" "}
								We may disclose information about you when we
								believe in good faith that disclosure is
								reasonably necessary to protect the property or
								rights of Noodle Chinese, third parties, or the
								public at large. For example, if we have a good
								faith belief that there is an imminent danger of
								death or serious physical injury, we may
								disclose information related to the emergency
								without delay.
							</p>
						</li>

						<li>
							<p>
								<strong>Business Transfers:</strong> In
								connection with any merger, sale of company
								assets, or acquisition of all or a portion of
								our business by another company, or in the
								unlikely event that Noodle Chinese goes out of
								business or enters bankruptcy, user information
								would likely be one of the assets that is
								transferred or acquired by a third party. If any
								of these events were to happen, this Privacy
								Policy would continue to apply to your
								information and the party receiving your
								information may continue to use your
								information, but only consistent with this
								Privacy Policy.
							</p>
						</li>

						<li>
							<p>
								<strong>With Your Consent:</strong> We may share
								and disclose information with your consent or at
								your direction. For example, we may share your
								information with third parties with which you
								authorize us to do so.
							</p>
						</li>

						<li>
							<p>
								<strong>
									Aggregated and De-Identified Information:
								</strong>{" "}
								We may share information that has been
								aggregated or reasonably de-identified, so that
								the information could not reasonably be used to
								identify you. For instance, we may publish
								aggregate statistics about the use of our
								Services.
							</p>
						</li>

						<li>
							<p>
								<strong>Published Support Requests:</strong> And
								if you send us a request (for example, via a
								support email or one of our feedback
								mechanisms), we reserve the right to publish
								that request in order to help us clarify or
								respond to your request or to help us support
								other users.
							</p>
						</li>
					</ul>

					<p>
						<strong>
							<em>Information Shared Publicly</em>
						</strong>
					</p>

					<p>
						Information that you choose to make public is–you
						guessed it–disclosed publicly. That means, of course,
						that information like your public profile and other
						content that you make public are all available to
						others–and we hope you get a lot of views! Public
						information may also be indexed by search engines or
						used by third parties. Please keep all of this in mind
						when deciding what you would like to share.
					</p>

					<h3 id="security">Security</h3>

					<p>
						While no online service is 100% secure, we work very
						hard to protect information about you against
						unauthorized access, use, alteration, or destruction,
						and take reasonable measures to do so.
					</p>

					<h3 id="choices">Choices</h3>

					<p>
						You have several choices available when it comes to
						information about you:
					</p>

					<ul>
						<li>
							<p>
								<strong>
									Limit the Information that You Provide:
								</strong>{" "}
								If you have an account with us, you can choose
								not to provide the optional account information,
								profile information, and transaction and billing
								information. Please keep in mind that if you do
								not provide this information, certain features
								of our Services may not be accessible.
							</p>
						</li>

						<li>
							<p>
								<strong>
									Limit Access to Information On Your Mobile
									Device:
								</strong>{" "}
								Your mobile device operating system should
								provide you with the ability to discontinue our
								ability to collect stored information or
								location information via our mobile apps. If you
								do so, you may not be able to use certain
								features.
							</p>
						</li>

						<li>
							<p>
								<strong>
									Opt-Out of Electronic Communications:
								</strong>{" "}
								You may opt out of receiving promotional
								messages from us. Just follow the instructions
								in those messages. If you opt out of promotional
								messages, we may still send you other messages,
								like those about your account and legal notices.
							</p>
						</li>

						<li>
							<p>
								<strong>
									Set Your Browser to Reject Cookies:
								</strong>{" "}
								At this time, Noodle Chinese does not respond to
								“do not track” signals across all of our
								Services. However, you can usually choose to set
								your browser to remove or reject browser cookies
								before using Noodle’s websites, with the
								drawback that certain features of Noodle
								Chinese’s websites may not function properly
								without the aid of cookies.
							</p>
						</li>
					</ul>

					<h3 id="other-things-you-should-know-keep-reading">
						Other Things You Should Know (Keep Reading!)
					</h3>

					<p>
						<strong>
							<em>Transferring Information</em>
						</strong>
						Noodle Chinese is a worldwide service. By accessing or
						using the Services or otherwise providing information to
						us, you consent to the processing, transfer, and storage
						of information in and to the U.S. and other countries,
						which may have rights and protections that are different
						from those in your home country.
					</p>

					<p>
						<strong>
							<em>
								Ads and Analytics Services Provided by Others
							</em>
						</strong>
						Ads appearing on any of our Services may be delivered by
						advertising networks. Other parties may also provide
						analytics services via our Services. These ad networks
						and analytics providers may set tracking technologies
						(like cookies) to collect information about your use of
						our Services and across other websites and online
						services. These technologies allow these third parties
						to recognize your device to compile information about
						you or others who use your device. This information
						allows us and other companies to, among other things,
						analyze and track usage, determine the popularity of
						certain content, and deliver advertisements that may be
						more targeted to your interests. Please note this
						Privacy Policy only covers the collection of information
						by Noodle Chinese and does not cover the collection of
						information by any third party advertisers or analytics
						providers.
					</p>

					<p>
						<strong>
							<em>Third Party Software</em>
						</strong>
						If you’d like to use third party software like plugins
						with our Services, please keep in mind that when you
						interact with them you may provide information about
						yourself to those third parties. We don’t own or control
						these third parties and they have their own rules about
						collection, use and sharing of information. You should
						review their rules and policies when installing and
						using third party software.
					</p>

					<p>
						<strong>
							<em>
								One More Thing: This Policy Is Available Under a
								Creative Commons Sharealike License
							</em>
						</strong>
					</p>

					<p>
						This privacy policy is adapted from{" "}
						<a href="https://automattic.com/">Automattic, Inc</a>{" "}
						under a Creative Commons Sharealike license. You’re more
						than welcome to copy it, adapt it, and repurpose it for
						your own use. Just make sure to revise the language so
						that your policy reflects your actual practices. Also,
						if you do use the policy Automattic would appreciate a
						credit and link to Automattic somewhere on your site.
					</p>

					<h3 id="privacy-policy-changes">Privacy Policy Changes</h3>

					<p>
						Although most changes are likely to be minor, Noodle
						Chinese may change its Privacy Policy from time to time.
						Noodle Chinese encourages visitors to frequently check
						this page for any changes to its Privacy Policy. If we
						make changes, we will notify you by revising the change
						log below, and, in some cases, we may provide additional
						notice (such as adding a statement to our homepage or
						sending you a notification through e-mail or your
						dashboard). Your continued use of the Services after any
						change in this Privacy Policy will constitute your
						consent to such change.
					</p>

					<h3 id="change-log">Change Log</h3>

					<p>No changes have yet been made.</p>
				</div>
			</main>
		);
	}
}
