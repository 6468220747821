import { connect } from "react-redux";
import PureComponent from "./component";

import { fetchWord } from "../../actions";

const mapStateToProps = (state, ownProps) => ({
	word: state.words[ownProps.match.params.chinese]
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	fetchWord: () => fetchWord(ownProps.match.params.chinese, dispatch)
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PureComponent);
