const reducer = (
	state = {
		query: "",
		results: {},
		words: {},
		lists: {},
		trendingWords: [],
		trendingLists: []
	},
	action
) => {
	switch (action.type) {
		case "SEARCH_START":
			return {
				...state,
				query: action.query
			};
		case "SEARCH_SUCCESS":
			if (action.query !== state.query) return state;
			return {
				...state,
				results: {
					...state.results,
					[action.query]: action.results
				}
			};
		case "WORD_SUCCESS":
			return {
				...state,
				words: {
					...state.words,
					[action.word.simplified]: action.word,
					[action.word.traditional]: action.word
				}
			};
		case "LIST_SUCCESS":
			return {
				...state,
				lists: {
					...state.lists,
					[action.list.id]: action.list
				}
			};
		case "FETCH_TRENDING_WORDS_SUCCESS":
			return {
				...state,
				trendingWords: action.trendingWords
			};
		case "FETCH_TRENDING_LISTS_SUCCESS":
			return {
				...state,
				trendingLists: action.trendingLists,
				lists: {
					...state.lists,
					...action.trendingLists.reduce((lists, list) => {
						lists[list.id] = list;
						return lists;
					}, {})
				}
			};
		default:
			return state;
	}
};

export default reducer;
