import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import SearchBar from "../SearchBar";

export default class Nav extends PureComponent {
	render() {
		return (
			<div className=" ph3">
				<div className="mw8 center flex items-center justify-between">
					<Link
						to="/"
						className="link pb2 f1 ttu fw9 orange nowrap mr3 wiggle outline-none"
					>
						~
					</Link>
					<SearchBar />
				</div>
			</div>
		);
	}
}
