import { connect } from "react-redux";
import PureComponent from "./component";
import { fetchList } from "../../actions";

const mapStateToProps = (state, ownProps) => ({
	list: state.lists[ownProps.match.params.id]
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	fetchList: () => fetchList(ownProps.match.params.id, dispatch)
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PureComponent);
