import { connect } from "react-redux";
import qs from "qs";
import PureComponent from "./component";

const mapStateToProps = (state, ownProps) => ({
	words: state.words,
	trendingWords: state.trendingWords,
	trendingLists: state.trendingLists,
	list: state.lists[ownProps.match.params.id],
	results:
		state.results[qs.parse(ownProps.location.search.replace("?", "")).q]
});

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PureComponent);
