import qs from "qs";

export function performSearch(query, dispatch) {
	dispatch({ type: "SEARCH_START", query });
	get(`search`, { query })
		.then(res =>
			dispatch({
				type: "SEARCH_SUCCESS",
				query,
				results: res
			})
		)
		.catch(error => dispatch({ type: "ERROR", error }));
}

export function fetchWord(query, dispatch) {
	dispatch({ type: "WORD_START", query });
	get(`word`, { query })
		.then(res =>
			dispatch({
				type: "WORD_SUCCESS",
				query,
				word: res
			})
		)
		.catch(error => dispatch({ type: "ERROR", error }));
}

export function fetchList(id, dispatch) {
	dispatch({ type: "LIST_START", id });
	get(`list`, { id })
		.then(res =>
			dispatch({
				type: "LIST_SUCCESS",
				id,
				list: res
			})
		)
		.catch(error => dispatch({ type: "ERROR", error }));
}

export function fetchTrending(dispatch) {
	dispatch({ type: "FETCH_TRENDING_WORDS_START" });
	get(`trending`)
		.then(res =>
			dispatch({
				type: "FETCH_TRENDING_WORDS_SUCCESS",
				trendingWords: res
			})
		)
		.catch(error => dispatch({ type: "ERROR", error }));
}

export function fetchLists(dispatch) {
	dispatch({ type: "FETCH_TRENDING_LISTS_START" });
	get(`lists`)
		.then(res =>
			dispatch({
				type: "FETCH_TRENDING_LISTS_SUCCESS",
				trendingLists: res
			})
		)
		.catch(error => dispatch({ type: "ERROR", error }));
}

function get(endpoint, query) {
	const queryString = qs.stringify(query);
	const URL = `${process.env.REACT_APP_API_ROOT}${endpoint}?${queryString}`;
	return fetch(URL).then(res => {
		if (res.status !== 200) {
			throw Error(res.statusText);
		}
		return res.json();
	});
}
