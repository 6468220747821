import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import SmallWord from "../SmallWord";

export default class Component extends PureComponent {
	componentDidMount() {
		this.props.fetchList();
	}

	render() {
		const { list } = this.props;
		const { match, location } = this.props;
		if (!list || !list.words) return null;
		if (list.id !== match.params.id) return null;
		const { pathname } = location;
		const title = `${list.title} - Noodle Chinese`;
		const description = `${
			list.subtitle ? `${list.subtitle} ` : null
		}Discover hundreds of Chinese vocab lists on Noodle.`;
		const URL = `https://www.noodlechinese.com${pathname}`;
		return (
			<section className="ph3">
				<Helmet>
					<title>{title}</title>
					<meta name="description" content={description} />
					<meta property="og:url" content={URL} />
					<meta property="og:type" content="website" />
					<meta property="og:title" content={title} />
					<meta property="og:description" content={description} />
					<meta property="og:image" content="/icon.png" />
				</Helmet>
				<div className="mw8 center flex">
					<div className="measure-wide w-100">
						<div className="flex pv4 items-center">
							<div
								className="h3 w3 flex items-center justify-center br2"
								style={{ backgroundColor: list.color }}
							>
								<span className="f2">{list.icon}</span>
							</div>
							<div>
								<h1 className="mv0 f3 fw7 ml3">
									{list.title || title}
								</h1>
								<span className="db ml3 mt1 dark-gray">
									{(list.words || []).length} Words
								</span>
							</div>
						</div>
						{this.renderWords()}
					</div>
				</div>
			</section>
		);
	}

	renderWords() {
		const { list } = this.props;
		if (!list || !list.words) return null;
		return (
			<div className="w-100">
				<h2 className="f4 mv3">Words</h2>
				<div className="mhn3">
					{list.words.map((word, index) => (
						<Link
							key={
								word.simplified +
								word.traditional +
								word.pinyin +
								index
							}
							to={`/word/${word.simplified}`}
							className="db link black ph3 br2 hover-bg-near-white pointer"
						>
							<div className="pv3 bb b--light-gray">
								<SmallWord word={word} />
							</div>
						</Link>
					))}
				</div>
			</div>
		);
	}
}
