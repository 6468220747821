import React, { PureComponent } from "react";

export default class SmallWord extends PureComponent {
	render() {
		const { word } = this.props;
		if (!word) return null;
		const { simplified, pinyin, definitions } = word;
		return (
			<div>
				<div className="truncate flex items-end">
					<span className="mr2 f4 kaiti">{simplified}</span>
					<span className="fw7 f5 truncate">{pinyin}</span>
				</div>
				<div className="truncate mt1">
					{this.renderDefinitions(definitions)}
				</div>
			</div>
		);
	}

	renderDefinitions(definitions) {
		if (!definitions) return null;
		return definitions.map((definition, index) => (
			<span key={definition} className="mid-gray truncate">
				{definition}
				{index < definitions.length - 1 ? "; " : ""}
			</span>
		));
	}
}
