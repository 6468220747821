import React, { PureComponent } from "react";
import { Link } from "react-router-dom";

import SmallWord from "../SmallWord";

export default class Search extends PureComponent {
	componentDidMount() {
		const { query, performSearch } = this.props;
		performSearch(query);
	}

	render() {
		const { query, results } = this.props;

		if (!results) return null;

		return (
			<section className="ph3">
				<div className="mw8 center mt3">
					<h1 className="f2 bb b--light-gray mv3 pv3">{query}</h1>
					{results.length ? this.renderResults() : this.renderEmpty()}
				</div>
			</section>
		);
	}

	renderResults() {
		const { results } = this.props;
		if (!results) return null;
		if (!results.length) return <span>No Results:(</span>;
		return (
			<div className="measure-wide">
				<h2 className="f4 mv2">Words</h2>
				{results.map(word => (
					<Link
						to={`/word/${word.simplified}`}
						key={word.simplified + word.traditional + word.pinyin}
						className="link black"
					>
						<div className="ph3 mhn3 hover-bg-near-white br2">
							<div className="pv3 bb b--light-gray">
								<SmallWord word={word} />
							</div>
						</div>
					</Link>
				))}
			</div>
		);
	}

	renderEmpty() {
		const { query } = this.props;
		return (
			<div className="measure-wide">
				<span className="f3" role="img" aria-label="sad face">
					😔
				</span>
				<h2 className="f4 mv2">Nothing found!</h2>
				<p className="f5 dark-gray">No results for "{query}"</p>
			</div>
		);
	}
}
