import { connect } from "react-redux";
import PureComponent from "./component";
import qs from "qs";

import { performSearch } from "../../actions";

const mapStateToProps = (state, ownProps) => {
	const query = qs.parse(ownProps.location.search.replace("?", "")).q;
	return {
		query,
		results: state.results[query]
	};
};

const mapDispatchToProps = (dispatch, ownProps) => ({
	performSearch: query => performSearch(query, dispatch)
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PureComponent);
