import { connect } from "react-redux";
import PureComponent from "./component";
import { fetchTrending, fetchLists } from "../../actions";

const mapStateToProps = (state, ownProps) => ({
	trendingWords: state.trendingWords,
	trendingLists: state.trendingLists
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	fetchTrending: () => fetchTrending(dispatch),
	fetchLists: () => fetchLists(dispatch)
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PureComponent);
